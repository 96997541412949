.navbar {
  background-color: var(--main-menu-background);
  color: white;
  padding: 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.page {
  margin-top: 100px;
  padding: 20px;
}

