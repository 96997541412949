.container {
  margin: 50px;
  padding: 24px;
  background: #fff;

  min-height: 280px;
  height: 100vh;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 100%;

  gap: 10px;

  .buttons {
    margin-top: 30px;
  }
}

.title {
  margin: 0 !important;
  text-align: center;
}

