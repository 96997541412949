.groupsPage {
    padding: 40px;
}

.groupsContent {
    background-color: var(--secondary-background);
    box-shadow: var(--box-shadow-base);
    padding: 30px;
}

.sectionButton {
    margin-bottom: 15px;
}
