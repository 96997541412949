.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 90vh;
  background-color: var(--secondary-background);
  margin: 40px;
  padding: 25px;

  h3, h4, h5, button {
    text-transform: uppercase;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 17px;
  }

  h5 {
    font-size: 14px;
  }

  main {
    padding: 0;
  }
}

