.event-layout-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e7e9ed;
}

.event-layout-content {
  overflow: auto;
}

.ant-layout-sider::-webkit-scrollbar {
  width: 8px !important;
}

.ant-layout-sider::-webkit-scrollbar-thumb {
  background: var(--text-disabled);
  border-radius: 10px;
}

.ant-layout-sider {
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 5s ease-in-out !important
  ;

  .ant-menu-title-content > a {
    color: var(--main-menu-text) !important;

    a {
      color: var(--main-menu-text) !important;
    }

    &-active {
      background: var(--main-menu-item-hovered) !important;
      color: var(--text-primary) !important;

      .ant-menu-title-content > a {
        color: var(--text-primary) !important;
      }
    }
  }

  .ant-anchor-wrapper {
    padding: 0;
    margin: 0;
    .ant-anchor-ink {
      display: none;
    }
    .ant-anchor-link {
      padding: 0;
    }

    .ant-menu-submenu-title {
      color: var(--main-menu-text);
      :hover {
        color: var(--text-primary);
      }
    }
  }

  .delimiter {
    height: 1px;
    background: #4f6b89;
    margin: 15px 0;
  }
  &.menu {
    background: var(--main-menu-background);
    color: white;
    padding: 10px;

    .menu-fold-icon {
      font-size: 20px;
      cursor: pointer;
      margin-bottom: 30px;
    }

    .ant-menu {
      background: var(--main-menu-background);
      color: white;
      border-right: 0;
      .ant-menu-title-content {
        margin-left: 15px;
      }
      .ant-menu-submenu-title {
        font-weight: bold;
      }
      &-item {
        color: var(--main-menu-text) !important;
        border-radius: 30px;

        &-active {
          background: var(--main-menu-item-hovered) !important;
          color: var(--text-primary) !important;

          .ant-menu-title-content > a {
            color: var(--text-primary) !important;
          }
        }

        &-selected {
          background: var(--main-menu-item-hovered) !important;
          color: var(--text-primary);
          .ant-menu-title-content > a {
            color: var(--text-primary);
          }
        }
      }
    }
  }
  &.sub-menu {
    padding: 0 10px;
    .ant-menu-submenu-selected {
      color: fade(black, 85%);
    }
    .ant-menu {
      border: 0;
    }
    .ant-menu-submenu {
      .ant-menu-submenu-title {
        font-weight: bold;
      }
      .ant-menu-sub {
        background: transparent;
      }
      .ant-menu-item {
        &:hover {
          background: var(--secondary-background-hovered);
        }
      }
      .ant-menu-item,
      .ant-typography {
        color: #909090 !important;
        font-weight: 400;
      }
    }
    .ant-menu-item {
      border-radius: 5px;
      font-weight: bold;
      &:hover {
        background: var(--secondary-background-hovered);
      }
    }
  }
}

.page-name {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;

  span > a {
    font-size: medium;
    line-height: 0;
  }
}

.menu::-webkit-scrollbar {
  width: 15px;
}

.menu::-webkit-scrollbar-track {
  background: var(--primary-background);
}

.menu::-webkit-scrollbar-thumb {
  background: #888;
}

.menu::-webkit-scrollbar-thumb:hover {
  background: #555;
}

