.container {
  padding: 40px;
}

.space {
  width: 100%;
}

.content {
  background-color: var(--secondary-background);
  box-shadow: var(--box-shadow-base);
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 30px;
}
