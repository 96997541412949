.avatar {
  border: 2px solid var(--primary-color);
  background: transparent !important;
  color: var(--primary-color) !important;
  cursor: pointer;
}

.popover {
  :global(.ant-popover-content) {
    :global {
      .ant-popover-inner {
        border-radius: 5px;
      }
      .ant-popover-inner-content {
        padding: 20px;
      }
      .ant-popover-arrow {
        display: none;
      }
    }

    .header {
      display: flex;
      align-items: center;
      line-height: 18px;

      .popoverAvatar {
        background: var(--primary-color);
      }

      .info {
        font-weight: bold;
        padding-left: 12px;
        font-size: 14px;
      }
    }
    .links {
      padding-left: 12px;
      font-size: 16px;
      .logout {
        color: var(--text-secondary);
      }
    }
  }
}

