.controls {
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
}

.selectedAttendees {
  padding: 0 10px;
  font-weight: bold;
}

.removeSelection {
  padding-left: 5px;
  cursor: pointer;
}

