.form {
  :global {
    .ant-divider {
      margin: 5px 0;
    }
  }
}

.formItem {
  text-align: inherit;
  margin: 5px 0;
}

.button, .buttonContainer {
  margin-top: 10px;
}

