.datePickerContainer {
  :global {
    .ant-picker {
      width: 100px;
      padding: 0;
    }

    .ant-picker-suffix {
      margin-top: -2px;
    }
  }
}

