.addressInfo {
  label {
    overflow-wrap: break-word;
  }
}

.formItem {
  text-align: inherit;
  height: 100%;
  margin: 0;

  :global(div:has(> .ant-form-item-explain)) {
    display: block !important;
  }

  :global(.ant-form-item-row) {
    height: 100%;

    :global(.ant-col) {
      display: flex;
    }

    :global(.ant-col:first-of-type) {
      align-items: center;
    }

    :global(.ant-col:last-of-type) {
      justify-content: center;
    }
  }
}

.formItemCol {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

