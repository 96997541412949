.company {
  padding: 20px;

  .basic-info {
    background-color: var(--secondary-background);
    padding: 30px 30px;
    box-shadow: var(--box-shadow-base);

    .header {
      display: flex;
      align-items: center;
    }

    .content {
      display: flex;
      padding-left: 75px;
      color: black;

      .info-column {
        padding: 0 5px;
        width: 33%;
        word-break: break-word;
        line-height: 2.1em;

        .title {
          color: var(--secondary-color);
        }

        .title::after {
          content: " ";
          white-space: pre;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  padding: 50px 0;
}

