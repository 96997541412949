.frequentlyUsedPages {
  margin-top: 35px;
}

.linkContainer {
  margin-top: 32px;
}

.link {
  word-wrap: space;
}

.link {
  display: flex;
  align-items: center;
  color: var(--primary-background) !important;
  padding: 0;
  margin-bottom: 20px;

  span:last-of-type {
    margin-bottom: 4px;
  }
}

.linkIcon {
  font-size: 32px;

  path {
    fill: var(--primary-background) !important;
  }
}

