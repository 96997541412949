.events-page {
  display: flex;
  justify-content: center;
  .event-wrapper {
    padding: 50px 10px 10px 10px;
  }
  .primary-button {
    .icon {
      color: var(--primary-color-backgroung);
    }
    display: flex;
    align-items: center;
    border-radius: 5px;
    color: var(--primary-color-backgroung);
  }

  .event-table-results {
    color: var(--table-color);
    margin-bottom: 10px;
    margin-top: 30px;
  }
  .favorite-icon {
    font-size: large;
    cursor: pointer;
    :hover {
      opacity: 0.9;
    }
  }
  .event-star-color-orange {
    color: var(--favourite-color);
  }

  .event-table-table {
    color: var(--table-color);

    -webkit-box-shadow: 0px 0px 14px 0px var(--shadow-color);
    -moz-box-shadow: 0px 0px 14px 0px var(--shadow-color);
    box-shadow: 0px 0px 14px 0px var(--shadow-color);
    .ant-table-thead {
      th {
        background: var(--table-color-background);
        color: var(--table-color);
      }
    }
    .ant-table-tbody {
      tr.ant-table-row:nth-child(even) {
        background: var(--table-color-background);
      }

      .forum-link {
        color: var(--link-primary);
      }
      .forum-link:hover {
        color: var(--icon-color-hover);
      }
    }
  }
}

