.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.deleteIcon {
  margin-left: 10px;
  cursor: pointer;
}

