.filtersBox {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  :global(.ant-select) {
    min-width: 150px;
  }
}

.bookletProfileSection {
  background-color: var(--secondary-background);
  padding: 30px;
  box-shadow: var(--box-shadow-base);
}

