.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.tag {
  border-radius: 20px;
  margin-top: 5px;
}
