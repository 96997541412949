.ql-picker.ql-insertCustomTags {
  width: 200px;
}

.ql-picker.ql-insertCustomTags .ql-picker-item::before, 
.ql-picker.ql-insertCustomTags .ql-picker-label::before {
  content: attr(data-label)
}

.ql-tooltip{
  left: auto!important;
  top: auto!important;
  z-index: 999;
}