.icon {
  font-size: 25px;
}

.greenFill {
  color: green;
}

.redFill {
  color: darkred;
}

.status {
  display: flex;
  gap: 10px;
  align-items: center;
}
