.attendee-page {
  padding: 20px;
  .contact-details {
    padding: 0;
    label[title] {
      font-weight: bold;
    }
  }
  .loader {
    display: flex;
    justify-content: center;
    padding: 50px 0;
  }
  .ant-divider {
    margin: 5px 0;
  }
}

