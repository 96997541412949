.container {
  display: flex;

  gap: var(--gap);
}

.verticalMiddle {
  align-items: center;
}

.verticalBottom {
  align-items: flex-end;
}

.horizontalMiddle {
  justify-content: center;
}

.horizontalRight {
  justify-content: flex-end;
}

