.descriptionSection {
  background-color: #f6f7f9;
  border-radius: 2px;
  height: 100%;
  padding: 20px;
}

.description {
  :global(.ant-typography:last-of-type) {
    margin-bottom: 0;
  }
}

