.editorText {
  word-break: break-word;
  white-space: pre-line;

  :global {
    h1 {
      font-size: 2em;
    }

    h2 {
      font-size: 1.5em;
    }

    p {
      margin-bottom: 0;
    }

    .ql-indent-1:not(.ql-direction-rtl) {
      padding-left: 3em;
    }

    .ql-indent-2:not(.ql-direction-rtl) {
      padding-left: 6em;
    }

    .ql-indent-3:not(.ql-direction-rtl) {
      padding-left: 9em;
    }

    .ql-indent-4:not(.ql-direction-rtl) {
      padding-left: 12em;
    }

    .ql-indent-5:not(.ql-direction-rtl) {
      padding-left: 15em;
    }

    .ql-indent-6:not(.ql-direction-rtl) {
      padding-left: 18em;
    }

    .ql-indent-7:not(.ql-direction-rtl) {
      padding-left: 21em;
    }

    .ql-indent-8:not(.ql-direction-rtl) {
      padding-left: 24em;
    }
  }
}

