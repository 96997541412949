.content {
  padding: 20px;
}

.layout {
  height: auto;
}

.info {
  margin-bottom: 20px;
}

