.header {
  height: auto;
  padding: 0;

  h3,
  h5 {
    margin: 0 !important;
  }

  :global {
    .ant-menu-horizontal,
    .ant-menu-item::after {
      border: none !important;
    }
  }
}

.menu {
  margin-top: 18px;
}

@media screen and (max-width: 1000px) {
  .searchContainer {
    margin-top: 20px;
  }
}

.searchContainer {
  display: flex;
  align-items: flex-start;
}

