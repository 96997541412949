.forums-list-component {
  margin: 50px;
}

.controls {
  margin-bottom: 8px;
  padding-right: 10px;
}

.details-link {
  color: var(--link-primary);
}

.filtered-title {
  color: var(--icon-color-hover);
}

