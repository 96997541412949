.layout {
  padding: 20px;
  height: auto;
}

.content {
  background-color: var(--secondary-background);
  box-shadow: var(--box-shadow-base);
  padding: 20px;
  min-height: 100%;
}

.reportsMenu {
  border-right: none;
  margin-top: 10px;
}

.reportsHeader {
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center; 
  justify-content: space-between;
  padding: 0 10px;
}

.cascader {
  min-width: 20vw;
}

.cascaderDropdown {
  :global(.ant-cascader-menu) {
    height: auto !important;
    max-height: 200px;
  }
}

.info {
  margin-bottom: 5px;
}
