.icon {
  font-size: 25px;
  color: gray;
}

.title {
  margin: 0 !important;
}

.error {
  display: block;
  color: red;
  padding-bottom: 5px;
}

.itinerarySection {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

