.table {
  table-layout: fixed;
  width: calc(100% + 10px);

  margin: 0 -5px;

  td {
    padding: 2px 10px;
    vertical-align: initial;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
}

