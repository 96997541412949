.completedDocuments {
  :global(.ant-table), button {
    font-size: 13px;
  }

  :global(.ant-table) {
    td p {
      word-break: initial;
    }
  }
}
