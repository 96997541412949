.icons {
  display: flex;
  align-items: center;
}

.icon {
  color: var(--icon-color);
  font-size: 24px;
  margin: 0 8px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &-clock {
    color: var(--clock-color);
  }

  &:hover {
    cursor: pointer;
    color: var(--icon-color-hover);
  }
}
.base-layout-header {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid var(--primary-color-border);
}

.system-admin-nav {
  margin: 0 30px 0 0;
  font-size: larger;
  display: flex;
  align-items: center;

  :first-child {
    margin-right: 2px;
    font-size: x-large;
  }
}

