.codeIcon {
  font-size: 25px;
  :hover {
    cursor: pointer;
    color: var(--icon-color-hover);
  }
}

.filteredSearch {
  color: var(--icon-color-hover);
}

.groupForm {
  height: 100%;
}

.formLayout {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

