.container {
  column-gap: 5px;
}

.title {
  color: var(--secondary-color);
}

.value {
  display: flex;
  align-items: center;
  min-height: 32px;
}

.editIcon {
  font-size: 20px;
  margin: 0 0 5px 5px;
}

