.page {
  padding: 20px;

  .controls {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .info {
      margin: 0 18px 0 auto;
    }
  }
}

.drawer {
  header {
    padding: 0;
  }
  section {
    background-color: transparent;
    height: 100%;
  }
  footer {
    background-color: transparent;
  }

  .drawer-button-section {
    padding-bottom: 20px;
  }
}

.link {
  color: var(--link-primary);
}

.filteredTitle {
  color: var(--icon-color-hover);
}

.selectedAttendees {
  padding: 0 10px;
  font-weight: bold;
}

.removeSelection {
  padding-left: 5px;
  cursor: pointer;
}

.header {
  background: white;
  padding: 10px 20px;

  :global button {
    margin-right: 5px;
  }
}
.tag, .tagDisabled {
  border-radius: 20px;
}

.tag {
  cursor: pointer;
}

.tagDisabled {
  &[ant-click-animating-without-extra-node='true']::after {
    display:none;
  }
}

.globalSearch {
  width: 300px;
  margin-right: 5px;
}
