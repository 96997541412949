@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;1,200;1,300;1,400;1,600;1,700;1,800&display=swap");

body {
  background-color: rgb(242, 244, 247);

  --box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  --box-shadow-secondary: 0px 0px 14px 0px rgba(0, 0, 0, 0.15);
}
:root {
  --background: white;
  --primary-color: #2448f6;
}
[data-theme="dark"] {
  --background: black;
  --text-primary: white;
  --text-secondary: grey;
  --accent: darkred;
}

[data-theme="main"] {
  @primary-color: #2448f6;

  --primary-color: #2448f6;
  --secondary-color: #77828f;
  --primary-background: rgb(21, 55, 92);
  --secondary-background: white;
  --primary-background-hovered: rgb(45, 75, 109);
  --secondary-background-hovered: fade(@primary-color, 10%);

  --text-disabled: #657d96;
  --text-primary: white;
  --text-secondary: #a7a7a7;

  --link-primary: rgba(0, 0, 0, 0.65);

  --border-color-base: #b7b7b7;

  --main-menu-background: var(--primary-background);
  --main-menu-item-hovered: var(--primary-background-hovered);
  --main-menu-text: #DCDCDC;

  --select-border: rgb(217, 217, 217);
  --table-color-base: rgb(138, 148, 159);
  --table-color-secondary: rgb(246, 247, 249);
  --th-color-base: rgb(138, 148, 159);
  --th-background-base: rgb(246, 247, 249);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgb(47, 52, 56);
}

* {
  font-family: "Nunito Sans", sans-serif !important;
}

#root {
  height: 100%;
}

.ant-layout {
  height: 100%;
}

.table-content .ant-table-tbody td {
  word-break: break-word;
}

[data-theme="main"] {
  --primary-color-backgroung: white;
  --primary-color-border: rgb(227, 229, 233);

  --secondary-color-background: rgb(242, 244, 247);

  --table-color: rgb(138, 148, 159);
  --table-color-background: rgb(246, 247, 249);

  --clock-color: rgb(52, 85, 246);
  --icon-color: rgb(131, 143, 160);
  --icon-color-hover: rgb(52, 85, 246);
  --favourite-color: orange;
  --shadow-color: rgba(0, 0, 0, 0.15);
}

