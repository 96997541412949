.layout {
  padding: 20px;
  height: auto;
}

.content {
  position: relative;
  min-height: 80vh;
}

