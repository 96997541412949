.contactDetails {
  span {
    overflow-wrap: break-word;
  }
}

.formItem {
  text-align: inherit;
}

