.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;

  .deleteIcon {
    font-size: large;
  }
}
