:global {
  .ant-image-preview-operations-operation-rotateLeft,
  .ant-image-preview-operations-operation-rotateRight {
    display: none !important;
  }

  #venueDetailsPage {
    .ant-collapse-content {
      .ant-divider {
        margin: 5px 0px;
      }
    }
  }
}

.col {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.item {
  text-align: inherit;
  height: 100%;
  padding: 5px 0;

  :global {
    div:has(> .ant-form-item-explain) {
      display: block !important;
    }

    .ant-form-item-row {
      height: 100%;

      .ant-col {
        display: flex;
      }

      .ant-col:first-of-type {
        align-items: center;
      }

      .ant-col:last-of-type {
        justify-content: center;
      }
    }
  }
}

