.filter {
  min-width: 210px;
}

.page {
  padding: 20px;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
}
