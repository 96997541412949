.content {
  background-color: var(--secondary-background);
  padding: 20px 30px;
  box-shadow: var(--box-shadow-base);
}

.layout {
  padding: 40px;
  height: auto;
}
