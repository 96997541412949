.watermarks {
  margin-top: 40px;

  :global(.ant-radio-wrapper) {
    span:has(figure) {
      padding: 0;
    }

    figure {
      width: 200px;

      .imageContainer {
        width: 100%;
        height: 200px;
        border: 2px solid #d9d9d9;
        border-radius: 8px;
        padding: 5px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .titleContainer {
        margin: 10px 0 0 !important;

        figcaption {
          font-weight: bold;
          word-wrap: break-word;
        }
      }
    }
  }

  :global(.ant-radio-wrapper-checked) {
    figure {
      .imageContainer {
        border-color: #096dd9;
      }
    }
  }

  :global(.ant-radio) {
    position: absolute;
    left: -9999px;
    overflow: hidden;
  }
}
