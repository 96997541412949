.questionLabel {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 15px;
}

.questionDeleteButton {
    position: absolute;
    right: 15px;
}
