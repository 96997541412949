.languageSwitch {
  border: 1px solid white;
  transition: 0.2s;
  &:hover {
    border: 1px solid var(--primary-color);
    transition: 0.2s;
  }
  border-radius: 20px;
  margin-right: 10px!important;
  :global {
    .ant-select-selector {
      box-shadow: none!important;
      border-radius: 20px!important;
      &:hover {
        border-color: transparent!important;
      }
    }
  }

}
