.form {
  .editor {
    border: 1px solid var(--primary-color-border);
    border-radius: 2px;
  }
  :global(.ant-form-item.ant-form-item-has-error) {
    .editor {
      border: 1px solid red;
    }
  }
}

