@import url("../../Theme/Themes/MainTheme/styles.less");

.table-wrapper {
  background-color: var(--secondary-background-color);
  padding: 30px 20px 30px 20px;
  box-shadow: var(--box-shadow-base);
}

.table-content {
  color: var(--table-color-base);
  -webkit-box-shadow: var(--box-shadow-secondary);
  -moz-box-shadow: var(--box-shadow-secondary);
  box-shadow:  var(--box-shadow-secondary);
  .ant-table-content {
    overflow: auto;
  }
  td.ant-table-column-sort {
    background: transparent;
  }
  .ant-table-thead {
    th {
      background: var(--th-background-base);
      color: var(--th-color-base);
    }
  }
  .ant-table-tbody {
    tr.ant-table-row:nth-child(even) {
      background: var(--table-color-secondary)
    }
    td{
      word-break: break-all;
    }
  }
  .ant-pagination.ant-table-pagination.ant-table-pagination-right {
    padding-right: 20px;
  }
}

