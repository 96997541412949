.filter {
  min-width: 210px;
}

.page {
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
}

