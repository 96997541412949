.optionWrapper {
  width: 100%;
  display: flex;
  align-items: center;

  :global(.delete-icon) {
    font-size: large;
  }

  .iconWrapper {
    margin-left: 10px;
  }
}
