.sendEmailDrawer {
  :global {
    .ant-select-multiple {
      .ant-select-selection-item {
        background: transparent;
        border-radius: 30px;
        border-color: var(--primary-color);
      }
    }
  }
}

.shadowDisabled {
  :global {
    .ant-select-selector {
      background: transparent !important;
      cursor: default !important;
      .ant-select-selection-item-content {
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
}

.emailPreview {
  padding-bottom: 20px;
}

.editor {
  min-width: 200px;
  overflow: auto;
}

