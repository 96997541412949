.subMenu {
  padding: 0 10px;

  :global {
    .ant-menu-submenu-selected {
      color: fade(black, 85%)
    }

    .ant-menu {
      border: 0;
    }

    .ant-menu-submenu {
      .ant-menu-submenu-title {
        font-weight: bold;
      }

      .ant-menu-sub {
        background: transparent;
      }

      .ant-menu-item {
        &:hover {
          background: var(--secondary-background-hovered);
        }
      }

      .ant-menu,
      .ant-typography {
        font-weight: 400;
      }

      .ant-menu-item,
      .ant-typography:not(.ant-typography-danger) {
        color: #909090 !important;
      }
    }

    .ant-menu-item {
      border-radius: 5px;
      font-weight: bold;

      &:hover {
        background: var(--secondary-background-hovered);
      }
    }
  }
}

.actions {
  margin: 10px 0;
}

.spacer {
  flex: 1;
}

