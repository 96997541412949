.logoUploadInput {
  :global(.ant-upload-btn) {
    padding: 0 !important;
  }
}

.uploadWrapper {
  padding: 15px 20px;
}

.deleteLogoIcon {
  position: absolute;
  bottom: 0;
  padding-left: 5px;
  color: gray;
  font-size: 15px;
  :hover {
    color: red;
  }
}

.previewImage {
  max-height: 80px;
  max-width: 100%;
}

.plusCircleIcon {
  font-size: 20px;
}

.sizedContainer {
  display: flex;
  align-items: center;
}
