.picker {
  :global {
    .ant-picker-content {
      height: 100% !important;

      .ant-picker-time-panel-column {
        overflow: hidden;
        display: flex;
        max-height: 450px;
        min-width: 200px;
        flex-flow: row;
        flex-wrap: wrap;

        .ant-picker-time-panel-cell-inner {
          padding: 10px !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .ant-picker-time-panel-cell {
          max-height: 30px;
        }
      }
    }
  }
}

