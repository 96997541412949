.venues-page {
  max-width: 100%;
  min-width: 480px;
  margin-top: 1px;
  padding: 0 0 0 0;

  .container {
    margin: 50px;

    .table-container {
      a {
        color: --var();
      }
    }
  }

  #newVenueForm {
    height: 100%;

    .form-layout {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }
  }
}

.venue-link {
  color: var(--link-primary);
}

