.list {
  display: flex;

  .listButton {
    background: var(--secondary-color-background);
    border-radius: 5px;
  }
  :global {
    .ant-select-selector {
      background: var(--secondary-color-background);
      border-radius: 5px;
    }

    button {
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
    button.active {
      opacity: 0.9;
      box-shadow: 0 0 2px 2px var(--icon-color-hover);
    }
  }
}

