.page {
  padding: 20px;

  .sessionTime {
    color: var(--text-secondary);
  }
}

.layout {
  height: auto;
}
