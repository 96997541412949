.form {
  height: 100%;

  .formLayout {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
}

