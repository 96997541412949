.generalPage {
  margin: 0 auto;
  max-width: 100%;
  min-width: 950px;
}

.collapseParent {
  padding: 0 40px;
  background-color: transparent !important;
  margin: 0 0 50px 0 !important;

  :global {
    .ant-collapse-header {
      display: flex !important;
      align-items: center !important;

      .ant-collapse-header-text {
        font-size: 20px;
      }
    }

    .ant-collapse-content-box {
      background-color: var(--primary-color-backgroung) !important;
      box-shadow: var(--box-shadow-base);
      padding: 40px !important;
    }
  }
}

.collapsePanel {
  padding-bottom: 50px;
  border-bottom: none !important;

  :global {
    .section-button {
      margin: 0 0 20px;
    }

    .ant-divider {
      margin: 5px 0px;
    }

    #socialLinksForm {
      .ant-form-item {
        margin: 5px 0px;
      }
    }
  }
}

.details {
  min-height: 310px;
}

.sizedContainer {
  height: 80px;
  display: flex;
  align-items: center;
}

.logo {
  max-height: 80px;
  max-width: 100%;
}

.detailsBox {
  padding: 5px 0;
}
