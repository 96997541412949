.participationCodesPage {
  max-width: 100%;
  min-width: 480px;
  margin-top: 1px;

  .container {
    margin: 50px;
  }

  :global(.table-container) {
    a {
      color: --var();
    }
  }
}

.fullWidth {
  width: 100%;
}

