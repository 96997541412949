.details {
  background-color: var(--secondary-background);
  padding: 30px 30px;
  margin: 20px 20px 0;
  box-shadow: var(--box-shadow-base);
  .loader {
    display: flex;
    justify-content: center;
    padding: 50px 0;
  }
  .header {
    display: flex;
    align-items: center;
    .company-name {
      font-size: 18px;
      font-weight: bold;
      padding-left: 30px;
    }
  }

  :global {
    .ant-collapse-content {
      .ant-divider {
        margin: 5px 0px;
      }
    }
  }
}

.formItem {
  display: flex;
  align-items: center;
  min-height: 100%;
  margin: 0;

  :global(.ant-form-item-row) {
    align-items: center;
    width: 100%;
  }
}

.label {
  overflow-wrap: break-word;
}

.logo {
  max-height: 80px;
  max-width: 100%;
}

