@import "../../../../../../components/styleguide/Theme/Themes/MainTheme/colors";

.container {
  display: flex;
  align-items: center;

  gap: 8px;
}

.spacer {
  flex: 1;
}

.selectedIcon {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';

    width: 5px;
    height: 5px;

    border-radius: 100%;

    background: @primary-color;
  }
}

