.event-select-select {
  position: relative;
  background: transparent !important;

  .ant-select-arrow {
    color: white !important;
  }

  &.cropped {
    .ant-select-arrow {
      color: transparent !important;
    }

    .ant-select-selection-item {
      padding: 0 !important;
      display: flex;
      justify-content: center;

      .event-name {
        display: none;
      }
    }
  }

  .ant-select-selection-item {
    .event-name {
      padding-left: 10px;
    }
  }

  &.ant-select-focused {
    .ant-select-selector {
      box-shadow: none !important;
    }
  }

  .ant-select-selector {
    color: white !important;
    background: transparent !important;
  }

  &:hover {
    .ant-select-selector {
      box-shadow: none !important;
    }
  }

  &_dropdown {
    position: absolute;
    width: auto !important;
    background: black !important;
  }
}

