.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.formItem {
  text-align: inherit;
  height: 100%;
  margin: 0;
}

.label {
  word-wrap: break-word;
}

