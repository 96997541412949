.form {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.formItem {
  min-width: 70px;
  margin-right: 0 !important;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0;
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;
}

.icon {
  font-size: 1.6em;
  color: var(--secondary-color);
  stroke-width: 15;
  stroke: white;
}
