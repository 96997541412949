.page {
  margin: 0px 40px auto;
  max-width: 100%;
  min-width: 480px;
  .collapse-panel {
    padding: 0px;
  }
  .collapse-parent {
    margin-bottom: 0px !important;
  }

  .venue-section {
    margin-bottom: 30px;
    padding: 20px;

    .venue-section-title {
      font-size: x-large;
      padding: 0 0 20px 0;
    }
    .venue-section-content {
      padding: 20px 20px 20px 20px;
      box-shadow: var(--box-shadow-secondary);
      background-color: var(--secondary-background);
    }
  }
}

.venu-details-layout-content {
  margin-left: 200px;
}

.header {
  margin: 1px 0 0 1px;

  button {
    margin-right: 5px;
  }
}

