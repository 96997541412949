.header {
  padding: 0 50px 0 35px !important;
}

.breadcrumbsWrapper {
  display: flex;
}

.logoLink {
  position: relative;
  top: 1px;
}

.sider {
  :global {
    .ant-anchor-link-title {
      font-weight: normal;
      color: var(--main-menu-text);
    }
    .ant-menu-item-icon {
      color: var(--main-menu-text);
    }

    .ant-menu-submenu-selected > .ant-menu-submenu-title {
      background: var(--main-menu-item-hovered);
      border-radius: 25px;
    }

    .ant-menu-item-active {
      .ant-anchor-link-title {
        color: white !important;
      }
    }

    .ant-menu-submenu-active > .ant-menu-submenu-title {
      background: var(--main-menu-item-hovered);
      border-radius: 25px;
      .ant-menu-title-content {
        color: white !important;
        a {
          color: white !important;
        }
      }
    }
  }
}

