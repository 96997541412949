.content {
  background-color: var(--secondary-background);
  box-shadow: var(--box-shadow-base);
  padding: 20px 30px;
}

.layout {
  height: auto;
  padding: 40px;
}
