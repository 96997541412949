.sider {
  position: fixed;
  top: 64px;
  bottom: 0;
  z-index: 999;

  overflow: auto;
}

.content {
  min-width: 500px;
  margin-left: var(--sider-width);
  padding: 0 40px;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

