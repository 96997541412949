.documentsTable {
    margin-top: 20px;
  }
  
.date_cell {
  word-break: keep-all;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
}
