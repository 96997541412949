.container {
  height: 100%;

  display: flex;
  flex-direction: column;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.spacer {
  flex: 1;
}

