.filters {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  
  :global(.ant-select) {
    min-width: 200px;
  }
}

.filtersBox {
  display: flex;
  justify-content: space-between;
}

.tables {
  display: flex;
  flex-wrap: flex;
  gap: 20px;
}

.tableSection {
  width: 100%;
}

