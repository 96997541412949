.presentItem {
  background-color: var(--adm-color-success);
  .text {
    color: white;
  }
}
.notPresentItem {
  background-color: var(--adm-color-danger);
  .text {
    color: white;
  }
}

:global {
  .adm-list-header {
    padding-left: 0;
    padding-right: 0;
  }

  .adm-swipe-action-actions-left {
    border-right: 1px solid white;
  }
  .adm-swipe-action-actions-right {
    border-left: 1px solid white;
  }
}

