.form {
  height: 100%;

  .formLayout {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
}
.tablesHeader {
  padding: 0;
}

.optionWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  :global(.delete-icon) {
    font-size: large;
  }
  .iconWrapper {
    margin-left: 10px;
  }
}

