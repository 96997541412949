.layout {
  padding: 20px;
  height: auto;
}

.content {
  background-color: var(--secondary-background);
  box-shadow: var(--box-shadow-base);
  padding: 20px;
  min-height: 100%;
}

