.section {
  background-color: var(--secondary-background);
  padding: 30px;
  box-shadow: var(--box-shadow-base);
}

.container {
  padding: 40px;
}

.tableContainerRow {
  margin-top: 16px;
}

.tableContainerCol {
  width: 100%;
}
