.collapse {
  padding: 0 20px 30px 20px;
  background-color: transparent !important;
  margin: 0 0 50px 0 !important;

  :global(.ant-collapse-header) {
    display: flex !important;
    align-items: center !important;

    :global(.ant-collapse-header-text) {
      font-size: 20px;
    }
  }

  :global(.ant-collapse-content-box) {
    background-color: var(--primary-color-backgroung) !important;
    box-shadow: var(--box-shadow-base);
    padding: 40px !important;
  }
}

.panel {
  padding-bottom: 50px;
  border-bottom: none !important;
}

