@media screen and (max-width: 1111px) and (min-width: 952px) {
  .footer {
    height: 150px;
  }
}

@media screen and (max-width: 951px) {
  .footer {
    height: 200px;
  }
}

.row {
  font-weight: bold;
  text-transform: uppercase;
  height: 50px;
}

.col {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &:first-of-type {
    justify-content: flex-start;
    margin-top: -3px;
  }

  &:last-of-type {
    justify-content: flex-end;
  }
}

