.testSection {
  background-color: var(--secondary-background);
  box-shadow: var(--box-shadow-base);
  padding: 30px;
}

.description {
  margin: 0 !important;
}

