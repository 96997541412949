.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  h1, p {
    margin: 0;
  }

  h1, p:first-of-type {
    font-weight: bold;
  }

  h1 {
    font-size: 8em;
    line-height: 0.9;
  }

  p:first-of-type {
    font-size: 3em;
  }

  p:last-of-type {
    font-size: 1.2em;
    margin-top: 12px;
  }

  button {
    height: 40px;
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 25px;
    padding: 5px 30px;
  }
}
