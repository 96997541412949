.link {
  display: block;
  line-height: 0;
  color: black;

  &.small {
    height: 17px;
  }

  &.big {
    height: 21px;
  }
}

.icon {
  stroke: white;

  &.normal {
    stroke-width: 15px;
  }

  &.bold {
    stroke-width: 5px;
  }

  &.small {
    font-size: 17px;
  }

  &.big {
    font-size: 21px;
  }
}
