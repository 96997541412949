.table {
  p {
    margin: auto;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .deleteIcon {
    margin-left: 10px;
    font-size: large;
  }
}

.sessionTime {
  color: var(--text-secondary);
}
