.deleteIcon:hover {
    color: red;
    cursor: pointer;
}

.buttonWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .deleteIcon {
        margin-left: 10px;
        font-size: large;
    }
}
