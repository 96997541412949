.container {
  padding: 30px;
  background-color: var(--secondary-background);
  box-shadow: var(--box-shadow-base);
}

.contentContainer {
  padding-top: 10px;
  padding-left: 30px;
}

.optionsButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.infoContainer {
  margin-top: 5px;
}

.attendeeName {
  font-size: 18px;
  font-weight: bold;
}

.infoRow {
  flex-direction: column;
}

.title {
  font-weight: bold;
  margin-right: 5px;
}

.bfrDateContainer {
  display: flex;
  align-items: center;
}

